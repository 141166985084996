import React from "react";
import { StyledGridRow } from "../../commons/Grid";
import { 
  StyledBusinessSection,
  StyledBusinessSectionTitle,
  StyledBusinessSectionSubitle,
} from "./style";

const BusinessSection = ({
  id, title, titleColor, subtitle, backgroundColor, paddingBottom, 
}) => (
  <StyledBusinessSection backgroundColor={backgroundColor} paddingBottom={paddingBottom}>
    <StyledGridRow noMargin>
      <StyledBusinessSectionTitle id={id} titleColor={titleColor}>
        {title}
      </StyledBusinessSectionTitle>
      {subtitle 
        && <StyledBusinessSectionSubitle dangerouslySetInnerHTML={{
          __html: subtitle,
        }}/>
      }
    </StyledGridRow>
  </StyledBusinessSection>
);

export default BusinessSection;
