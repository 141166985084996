import styled from "styled-components";
import { Rem, Colors, BP } from "../../commons/Theme";

export const StyledBusinessSection = styled.div`
  background-color: ${({ backgroundColor }) => (backgroundColor === "grey") ? Colors.grayLight : ""};
  padding-top: ${Rem(48)};
  padding-bottom: ${(props) => Rem(props.paddingBottom ?? 20)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(72)};
    padding-bottom: ${(props) => Rem((props.paddingBottom ?? 20) * 1.5)};
`;

export const StyledBusinessSectionTitle = styled.h2`
  font-size: ${Rem(30)};
  margin-bottom: ${Rem(25)};
  color: ${(props) => props.titleColor ?? Colors.yellow};
  text-transform: uppercase;
  font-weight: 900;

  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
    margin-bottom: ${Rem(30)};
  }
`;

export const StyledBusinessSectionSubitle = styled.h4`
  font-size: ${Rem(15)};
  line-height: ${Rem(17)};
  margin-bottom: ${Rem(25)};
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: ${Rem(3)};

  @media (${BP.ipad}) {
    font-size: ${Rem(20)};
    line-height: ${Rem(30)};
    margin-bottom: ${Rem(30)};
    letter-spacing: ${Rem(5)};
  }
`;
